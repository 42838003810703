<template>
    <Toolbar class="p-mb-4">
        <template v-slot:left>
            <Button label="Ajouter un utilisateur" icon="pi pi-plus" class="p-button-success p-mr-2" @click="createUser" />
        </template>
    </Toolbar>
    <Dialog v-model:visible="addUser" :style="{ width: '600px' }" header="Ajoute d'un utilisateur" :modal="true" class="p-fluid" :closable="false">
        <CreateUser :onClose="closeCreate" />
    </Dialog>
    <Dialog v-model:visible="visible" :style="{ width: '600px' }" header="Gestion de l'utilisateur" :modal="true" class="p-fluid" :closable="false">
        <ModifyUser :onClose="hidden" :id="this.selectedUser.id" :firstName="this.selectedUser.firstName" :lastName="this.selectedUser.lastName" :email="this.selectedUser.email" :attributes="this.selectedUser.attributes" />
    </Dialog>
    <DataTable :value="users" :rowHover="true" selectionMode="single" v-model:selection="selectedUser" @click="modify">
        <Column field="username" header="Username"></Column>
        <Column field="firstName" header="FirstName"></Column>
        <Column field="lastName" header="LastName"></Column>
        <Column field="email" header="email"></Column>
    </DataTable>
</template>

<script>
import axios from 'axios';
import ModifyUser from './ModifyUser.vue';
import CreateUser from './CreateUser.vue';
export default {
    components: {
        ModifyUser,
        CreateUser,
    },
    name: 'ListUsers',
    data() {
        return {
            selectedUser: null,
            visible: false,
            users: null,
            addUser: false,
        };
    },
    methods: {
        modify() {
            if (this.selectedUser) {
                this.visible = true;
            }
        },
        hidden() {
            this.selectedUser = null;
            this.visible = false;
            this.reloadData();
        },
        createUser() {
            this.addUser = true;
        },
        closeCreate() {
            this.addUser = false;
            this.reloadData();
        },
        reloadData() {
            axios
                .get(process.env.VUE_APP_USER_API_URL + '/user/list', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('user-token'),
                    },
                })
                .then(res => {
                    this.users = res.data.users;
                });
        },
    },
    created() {
        this.reloadData();
    },
};
</script>
