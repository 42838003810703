<template>
    <div class="card">
        <div class="p-fluid grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText id="inputtext-left" type="text" v-model="this.user.username" placeholder="Username" required/>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <InputText id="inputtext-left" type="text" v-model="this.user.firstName" placeholder="FirstName" required/>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <InputText id="inputtext-right" type="text" v-model="this.user.lastName" placeholder="LastName" required />
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <InputText id="inputtext-right" type="text" v-model="this.user.email" placeholder="Email" required />
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <Dropdown id="dropdown" v-model="this.user.poste" :options="postes" optionLabel="name" placeholder="Poste" />
                </span>
            </div>
            <div class="field col-12 md:col-4" v-if="this.user.poste.name.includes('Dev') || this.user.poste.name.includes('Dév')">
                <span class="p-float-label">
                    <InputText id="inputtext-left" type="text" v-model="this.user.instance_hostname" placeholder="Instance Hostname" />
                </span>
            </div>
            <div class="field col-12 md:col-4" v-if="this.user.poste.name.includes('Dev') || this.user.poste.name.includes('Dév')">
                <span class="p-float-label">
                    <InputText id="inputtext-left" type="text" v-model="this.user.instance_name" placeholder="Instance Name" />
                </span>
            </div>
        </div>
    </div>
    <signature :firstname="this.user.firstName" :lastname="this.user.lastName" :email="this.user.email" :work="this.user.poste.name" :picture="this.user.picture" />
    <div style="display: flex; margin-top:10px;">
        <Button label="Abort" icon="pi pi-times" @click="hidden('abort')" class="p-button-text" />
        <Button label="Create" icon="pi pi-check" @click="hidden('modify')" autofocus />
    </div>
</template>

<script>
import { ref } from 'vue';
import Signature from '../signature/Signature.vue';
import axios from 'axios';

export default {
    name: 'CreateUser',
    props: {
        onClose: Function,
    },
    components: {
        Signature,
    },
    data() {
        return {
            user: {
                firstName: null,
                lastName: null,
                email: null,
                poste: { name: '' },
                picture: null,
            },
            postes: ref([{ name: '' }, { name: 'DevOps'}, { name: 'Développeur'}, { name: 'Ressources Humaines' }, { name: 'Commercial' }, { name: 'Commerciale' }, { name: 'Développeuse'}, { name: 'Resp rh' }]),
        };
    },
    mounted() {
        this.visible = true;
        this.user.username = null;
        this.user.firstName = '';
        this.user.lastName = '';
        this.user.email = '';
        this.user.poste = '';
        this.user.picture = '';
        this.user.instance_name = null;
        this.user.instance_hostname = null;
    },
    methods: {
        hidden(e) {
            if (e == 'modify') {
                const attributes = {picture: this.user.picture,poste: this.user.poste.name,};
                if (this.user.poste.name.includes('Dev') || this.user.poste.name.includes('Dév')) {
                    attributes.instance_name = this.user.instance_name; 
                    attributes.instance_hostname = this.user.instance_hostname; 
                }
                axios
                    .post(
                        process.env.VUE_APP_USER_API_URL + '/user/create',
                        {
                            username: this.user.username,
                            firstName: this.user.firstName,
                            lastName: this.user.lastName,
                            email: this.user.email,
                            attributes: attributes,
                        },
                        { headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') } }
                    )
                    .then(() => {
                        this.onClose();
                    });
            } else this.onClose();
        },
    },
};
</script>
